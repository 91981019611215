import { GeneralPrebidModuleData } from './types'

export const KNOWLEDGE_BASE_URL =
  'https://knowledge-base.yieldbird.com/prebid-stack/general-modules'

export const GENERAL_PREBID_MODULES: GeneralPrebidModuleData = {
  FPD: {
    name: 'Topics First Party Data (FPD)',
    tooltip: {
      content:
        'This module calls the Topics API (document.browsingTopics()), which fetches the first-party Domain and third-party Domain (Iframe) topics data. This data will be sent to user data in the bid stream.',
      url: 'https://docs.prebid.org/dev-docs/modules/topicsFpdModule.html',
    },
  },
  PAAPI: {
    name: 'PAAPI (Fledge) for GPT',
    tooltip: {
      content: `Tooltip: This module for Prebid.js to support PAAPI (formerly FLEDGE) by integrating with GPT's support for component auctions`,
      url: 'https://docs.prebid.org/dev-docs/modules/paapiForGpt.html',
    },
  },
}
