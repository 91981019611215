import { CellContext } from '@tanstack/react-table'

import { DATE_FORMAT, formatDate } from 'utils/date'

type LastModifiedCellProps<
  T extends {
    updatedAt: Date
  },
> = { dateFormat?: string } & CellContext<T, unknown>

const LastModifiedCell = <
  T extends {
    updatedAt: Date
  },
>({
  dateFormat = DATE_FORMAT.DEFAULT,
  row: {
    original: { updatedAt },
  },
}: LastModifiedCellProps<T>): JSX.Element => <>{formatDate(updatedAt, dateFormat)}</>

export default LastModifiedCell
