import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Schema } from 'features/globalSetup/features/amazon/forms/GeneralPageForm'
import { WorkspaceAmazonSlice } from 'features/globalSetup/features/amazon/hooks/useGetWorkspaceAmazon'
import { useAbility } from 'providers/casl'
import { KONWLEDGE_BASE_URL } from '../../constants'
import { Props, TooltipProps, UseSwitchProps } from './types'
import { DISABLED_NO_TOOLTIP, ENABLED_NO_TOOLTIP } from './utilts'

export const useSwitchProps = ({ workspaceAmazon }: Props): UseSwitchProps => {
  const ability = useAbility()
  const { t } = useTranslation(['features/globalSetup/amazon', 'common'])

  const { control, getFieldState, watch } = useFormContext<Schema>()
  const formValues = watch()

  const ownCase = (): TooltipProps => {
    const { invalid } = getFieldState('pubId')
    return invalid ? DISABLED_NO_TOOLTIP : ENABLED_NO_TOOLTIP
  }

  const yieldbirdCase = (workspaceAmazonStatus: WorkspaceAmazonSlice['status']): TooltipProps => {
    switch (workspaceAmazonStatus) {
      case 'available': {
        return ENABLED_NO_TOOLTIP
      }
      case 'requested': {
        return {
          disabled: true,
          tooltip: {
            content: t('pages.general.amazonOnOff.tooltip.yieldbirdRequested'),
            link: {
              title: t('common:learnMore'),
              url: KONWLEDGE_BASE_URL.TOOLTIP,
            },
          },
        }
      }
      case 'denied': {
        return DISABLED_NO_TOOLTIP
      }
    }
  }

  const thirdPartyProviderCase = (): TooltipProps => {
    const { invalid: invalidPubId } = getFieldState('pubId')
    const { invalid: invalidSupplyChainId } = getFieldState('supplyChainId')
    return invalidPubId || invalidSupplyChainId ? DISABLED_NO_TOOLTIP : ENABLED_NO_TOOLTIP
  }

  const tooltipProps = (): TooltipProps => {
    if (ability.cannot('update', 'GlobalSetupFeature-amazon')) {
      return DISABLED_NO_TOOLTIP
    }

    if (_.isNil(workspaceAmazon)) {
      return {
        disabled: true,
        tooltip: {
          content: t('pages.general.amazonOnOff.tooltip.disabled'),
        },
      }
    }

    switch (formValues.accountType) {
      case 'own': {
        return ownCase()
      }
      case 'yieldbird': {
        return yieldbirdCase(workspaceAmazon.status)
      }
      case 'thirdPartyProvider': {
        return thirdPartyProviderCase()
      }
    }
  }

  return {
    control,
    name: 'enabled',
    tooltipMode: 'wrap',
    ...tooltipProps(),
  }
}
