import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { SetupPrebidStackSectionContext } from 'features/globalSetup/contexts/SetupPrebidStackSectionContext'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { DATE_FORMAT, formatDate } from 'utils/date'
import { ErrorBox } from '../../../../components/ErrorBox'
import { StatusBox } from '../../../../components/StatusBox'
import { useConfig } from './useConfig'

export const ConnectionStatus = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.setupPrebidStack',
  })
  const { gamSetupOrder } = useContext(SetupPrebidStackSectionContext)
  const messageStatus: 'completed' | 'inProgress' =
    gamSetupOrder?.status === 'completed' ? 'completed' : 'inProgress'
  const { color } = useConfig(gamSetupOrder?.status)

  if (!gamSetupOrder) {
    return <></>
  }

  const isSetupFailed = gamSetupOrder?.status === 'failed'

  return (
    <>
      {!isSetupFailed ? (
        <StatusBox
          color={color}
          message={t(`status.${messageStatus}`, {
            updatedAt: formatDate(gamSetupOrder.updatedAt, DATE_FORMAT.WITH_TIME),
          })}
        />
      ) : (
        <ErrorBox
          errorMessage={gamSetupOrder.errorMessage}
          sx={{ mb: CONTAINER_SPACING }}
        />
      )}
    </>
  )
}
