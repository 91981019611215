import { Card, CardHeader, Checkbox, Divider, List } from '@mui/material'
import _ from 'lodash'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ListItemButton } from 'components/List'
import { TransferListContext } from '../contexts/TransferListContext'
import { TransferListItem } from '../types'
import { CustomListProps } from './types'

export const CustomList = ({ items, title }: CustomListProps): JSX.Element => {
  const { t } = useTranslation('components')
  const {
    areAllChecked,
    checked,
    indeterminateChecked,
    isDisabled,
    numberOfChecked,
    select,
    selectAll,
  } = useContext(TransferListContext)

  const HeaderCheckbox = (): JSX.Element => (
    <Checkbox
      checked={areAllChecked(items)}
      data-cy='checkbox-all'
      disabled={isDisabled || _.isEmpty(items)}
      indeterminate={indeterminateChecked(items)}
      onClick={selectAll(items)}
    />
  )

  const subheader: string = t('transferList.selected', {
    all: items.length,
    interpolation: { escapeValue: false },
    selected: numberOfChecked(items),
  })

  return (
    <Card
      sx={{
        borderRadius: 0,
        width: '100%',
      }}
    >
      <CardHeader
        avatar={<HeaderCheckbox />}
        subheader={subheader}
        title={title}
      />

      <Divider />

      <List
        sx={{
          height: 400,
          overflowY: 'auto',
          p: 0,
        }}
      >
        {items.map((item: TransferListItem): JSX.Element => {
          const { label, value } = item

          return (
            <ListItemButton
              disabled={isDisabled}
              icon={
                <Checkbox
                  checked={checked.includes(item)}
                  disabled={isDisabled}
                />
              }
              key={value}
              onClick={select(item)}
              text={label}
              withTooltip
            />
          )
        })}
      </List>
    </Card>
  )
}
