import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import {
  WorkspaceKeyValueSlice,
  WorkspaceKeyValueSortBy,
} from '../../sections/KeyValuesSection/types'
import { WorkspaceKeyValueSliceDTO, WorkspaceKeyValueSortByDTO } from './types'

export const workspaceKeyValueFromDTO = (from: WorkspaceKeyValueSliceDTO): WorkspaceKeyValueSlice =>
  mapDTO<WorkspaceKeyValueSliceDTO, WorkspaceKeyValueSlice>({ from }).transform(dto => {
    const { errorMessage, id: workspaceKeyValueId, keyValue, status, updatedAt } = dto
    const { id: keyValueId, name, tooltip, type } = keyValue

    return {
      error: errorMessage || undefined,
      id: workspaceKeyValueId,
      keyValue: {
        id: keyValueId,
        name,
        tooltip: tooltip || undefined,
        type,
      },
      status,
      updatedAt: new Date(updatedAt),
    }
  })

export const sortToDTO = (
  sort: SortInput<WorkspaceKeyValueSortBy>,
): SortInput<WorkspaceKeyValueSortByDTO> => {
  const { by } = sort

  if (by === 'keyValue.name') {
    return { ...sort, by: 'keyValueName' }
  } else {
    return { ...sort, by }
  }
}
