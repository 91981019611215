import { TooltipProps } from './types'

export const ENABLED_NO_TOOLTIP: TooltipProps = {
  disabled: false,
  tooltip: undefined,
} as const

export const DISABLED_NO_TOOLTIP: TooltipProps = {
  disabled: true,
  tooltip: undefined,
} as const
