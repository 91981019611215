import { useTranslation } from 'react-i18next'

import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { WorkspacePrebidModuleTable } from '../../components/WorkspacePrebidModuleTable'
import { KNOWLEDGE_BASE_URL } from './constants'
import { useColumns } from './hooks/useColumns'

export const GeneralList = (): JSX.Element => {
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const columns = useColumns()

  return (
    <Paper>
      <TableHeader
        bottomSpacing
        description={t('prebid.modules.general.list.description')}
        descriptionEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE_URL,
            }}
          />
        }
        title={t('prebid.modules.general.list.title')}
      />

      <WorkspacePrebidModuleTable
        columns={columns}
        moduleType='general'
      />
    </Paper>
  )
}
