import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, Switch, TextField } from 'components/Form'
import { SetupPrebidStackSectionContext } from 'features/globalSetup/contexts/SetupPrebidStackSectionContext'
import { useAbility } from 'providers/casl'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { control, watch } = useFormContext<Schema>()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.setupPrebidStack',
  })
  const { nativeEnabled } = watch()
  const { available } = useContext(SetupPrebidStackSectionContext)
  const ability = useAbility()
  const disabled =
    !available ||
    ability.cannot('update', 'GlobalSetupFeature-googleAdManagerConnection-setupPrebidStack')

  return (
    <BoxForm sx={{ mb: 2 }}>
      <Switch
        control={control}
        disabled
        label={t('form.bannerEnabled.label')}
        name='bannerEnabled'
      />

      <TextField
        control={control}
        disabled={disabled}
        label={t('form.bannerTemplateId.label')}
        name='bannerTemplateId'
      />

      <Switch
        control={control}
        disabled={disabled}
        label={t('form.nativeEnabled.label')}
        name='nativeEnabled'
      />

      {nativeEnabled && (
        <TextField
          control={control}
          disabled={disabled}
          label={t('form.nativeTemplateId.label')}
          name='nativeTemplateId'
        />
      )}
    </BoxForm>
  )
}
