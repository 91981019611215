import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { DomainBidder } from 'features/globalSetup/types/domainBidder'
import { AdUnitSortBy } from '../../../components/AdUnitsTable/types'
import { AdUnitBidderSlice } from '../types'
import { AdUnitBidderSliceDTO, AdUnitSortByDTO } from './types'
import { mergeDomainBidderSchema } from './utils'

type From = AdUnitBidderSliceDTO
type To = AdUnitBidderSlice

export const fromDTO = (
  from: From,
  csParamsSchema: DomainBidder['csParamsSchema'],
  s2sParamsSchema: DomainBidder['s2sParamsSchema'],
): To =>
  mapDTO<From, To>({ from }).transform((adUnitBidder: From): To => {
    const {
      adUnit: { divId, id: adUnitId, path },
      csEnabled,
      csParams,
      id,
      s2sEnabled,
      s2sParams,
      updatedAt,
    } = adUnitBidder

    return {
      adUnit: {
        divId: divId || undefined,
        id: adUnitId,
        path,
      },
      csEnabled,
      csParams: mergeDomainBidderSchema(csParams, csParamsSchema),
      id,
      s2sEnabled,
      s2sParams: mergeDomainBidderSchema(s2sParams, s2sParamsSchema),
      updatedAt: new Date(updatedAt),
    }
  })

export const sortToDTO = (sort: SortInput<AdUnitSortBy>): SortInput<AdUnitSortByDTO> => {
  const { by } = sort

  if (by === 'adUnit.path') {
    return { ...sort, by: 'adUnitPath' }
  } else if (by === 'adUnit.divId') {
    return { ...sort, by: 'adUnitDivId' }
  } else {
    return { ...sort, by }
  }
}
