import { GamSetupOrder } from 'features/workspace/types/gamSetupOrder'
import { UseConfig } from './types'

export const useConfig = (status?: GamSetupOrder['status']): UseConfig => {
  switch (status) {
    case 'completed':
      return {
        color: 'success.main',
      }

    case 'inProgress':
      return {
        color: 'warning.main',
      }

    default:
      return {
        color: undefined,
      }
  }
}
