import { CellContext } from '@tanstack/react-table'

import { Switch } from 'components/Switch'
import { WorkspacePrebidModuleSlice } from 'features/globalSetup/pages/Prebid/Modules/hooks/useGetWorkspacePrebidModules'
import { useUpdateWorkspacePrebidModuleEnabled } from 'features/globalSetup/pages/Prebid/Modules/hooks/useUpdateWorkspacePrebidModuleEnabled'
import { useAbility } from 'providers/casl'

export const EnabledCell = ({
  getValue,
  row: {
    original: {
      id,
      prebidModule: { name: prebidModuleName },
    },
  },
}: CellContext<WorkspacePrebidModuleSlice, unknown>) => {
  const ability = useAbility()

  const { checked, update } = useUpdateWorkspacePrebidModuleEnabled({
    id,
    initialChecked: Boolean(getValue()),
    prebidModuleName,
  })

  return (
    <Switch
      checked={checked}
      disabled={ability.cannot('update', 'GlobalSetupFeature-general')}
      name={`workspace-prebid-module-general-enabled-${id}`}
      onChange={update}
    />
  )
}
