import { SortInput } from 'components/Table'
import { WorkspaceKeyValueSortBy } from './types'

export const KNOWLEDGE_BASE_URL =
  'https://knowledge-base.yieldbird.com/yieldbird-platform/gam-api-connection#block-3f8347c65ae54c3a91b5443f7b5ddfaf' as const

export const DEFAULT_SORT: SortInput<WorkspaceKeyValueSortBy> = {
  by: 'updatedAt',
  direction: 'desc',
} as const

export const SORTABLE_COLUMNS: WorkspaceKeyValueSortBy[] = ['keyValue.name', 'updatedAt']
