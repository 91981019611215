import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceDTO } from '../types/workspaceDTO'

export const WORKSPACE_FRAGMENT: TypedDocumentNode<WorkspaceDTO> = gql`
  fragment WorkspaceFragment on Workspace {
    id
    active
    alerts
    createdAt
    crmId
    csManager {
      id
      firstName
      lastName
    }
    failsafeTimeout
    name
    networkCode
    pipedriveLink
    prebidManagerAccountId
    prebidManagerSampling
    priceGeniusAlwaysOn
    primaryCurrency
    updatedAt
  }
`
