import { TableRow } from '@mui/material'
import { Cell, Row } from '@tanstack/react-table'

import { MemoizedCell } from './CellWrapper'
import { RowsProps } from './types'

export const Rows = <T,>({ onClickRow, onClickRowAsync, rows }: RowsProps<T>): JSX.Element => (
  <>
    {rows.map(
      (row: Row<T>, index: number): JSX.Element => (
        <TableRow
          data-cy={`table-row-${index}`}
          hover
          key={row.id}
        >
          {row
            .getVisibleCells()
            .filter((cell: Cell<T, unknown>) => Boolean(!cell.column.columnDef.meta?.hidden))
            .map(
              (cell: Cell<T, unknown>): JSX.Element => (
                <MemoizedCell
                  key={cell.id}
                  {...{ cell, onClickRow, onClickRowAsync }}
                />
              ),
            )}
        </TableRow>
      ),
    )}
  </>
)
