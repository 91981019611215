import { mapDTO } from '@twistezo/ts-dto-mapper'
import { isNil } from 'lodash'

import { GamSetupOrderDTO } from '../api/types/gamSetupOrderDTO'
import { GamSetupOrder } from '../types/gamSetupOrder'

export const gamSetupOrderFromDTO = (from: GamSetupOrderDTO): GamSetupOrder =>
  mapDTO<GamSetupOrderDTO, GamSetupOrder>({ from }).transform(dto => ({
    ...dto,
    bannerTemplateId: isNil(dto.bannerTemplateId) ? '' : String(dto.bannerTemplateId),
    createdAt: new Date(dto.createdAt),
    errorMessage: dto.errorMessage || undefined,
    nativeTemplateId: isNil(dto.nativeTemplateId) ? '' : String(dto.nativeTemplateId),
    status: mapStatusFromDTO(dto.status),
    updatedAt: new Date(dto.updatedAt),
  }))

const mapStatusFromDTO = (status: GamSetupOrderDTO['status']): GamSetupOrder['status'] => {
  switch (status) {
    case 'completed':
      return 'completed'
    case 'failed':
      return 'failed'
    case 'queued':
      return 'inProgress'
    case 'started':
      return 'inProgress'
  }
}
