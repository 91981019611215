import { Can } from '../../Can'

export const onPageListEditAccess = (can: Can) => {
  can('access', 'PagePage-list')
  can('access', 'PagePage-edit')
}

export const onPageAddAccess = (can: Can) => {
  can('access', 'PagePage-add')
}

export const onPageCreateUpdateDelete = (can: Can) => {
  can('create', 'Page')
  can('update', 'Page')
  can('delete', 'Page')
}
