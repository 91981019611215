import { isNil } from 'lodash'
import { z } from 'zod'

import { Workspace } from 'features/workspace'
import { GamSetupOrder } from 'features/workspace/types/gamSetupOrder'

export const schema = z.object({
  bannerEnabled: z.boolean(),
  bannerTemplateId: z.string().optional(),
  id: z.string().optional(),
  nativeEnabled: z.boolean(),
  nativeTemplateId: z.string().optional(),
  workspaceId: z.string(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = (
  workspaceId: Workspace['id'],
  gamSetupOrder?: GamSetupOrder,
): Schema => {
  const { bannerTemplateId, nativeEnabled, nativeTemplateId } = gamSetupOrder ?? {}

  return {
    bannerEnabled: true,
    bannerTemplateId,
    id: gamSetupOrder?.id,
    nativeEnabled: isNil(nativeEnabled) ? false : nativeEnabled,
    nativeTemplateId,
    workspaceId,
  }
}
