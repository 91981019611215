import { Theme, ThemeOptions } from '@mui/material'

import { BUTTON_BORDER_RADIUS, inputWidth, TOOLBAR_HEIGHT } from './constants'

export const createComponents = (theme: Theme): ThemeOptions['components'] => ({
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '&.ChipsListFilterable .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&.ChipsListFilterable .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline':
          {
            border: 0,
          },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: `${BUTTON_BORDER_RADIUS}px`,
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'movement',
    },
  },
  MuiChip: {
    variants: [
      {
        props: { variant: 'filledWhiteText' },
        style: {
          color: theme.palette.white.main,
        },
      },
    ],
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        minWidth: 320,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        minWidth: inputWidth['md'],
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[600],
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.h6.fontWeight,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight,
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: BUTTON_BORDER_RADIUS,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: BUTTON_BORDER_RADIUS,
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'movement',
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        '&.MuiInputBase-sizeSmall': {
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.body2.fontWeight,
        },
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'movement',
    },
  },
  MuiTabs: {
    defaultProps: {
      indicatorColor: 'secondary',
      textColor: 'secondary',
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.white.main,
      },
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: {
      color: 'movement',
    },
  },
  MuiToolbar: {
    defaultProps: {
      style: {
        height: TOOLBAR_HEIGHT,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        '&, a': {
          color: theme.palette.white.main,
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.body2.fontWeight,
          textDecorationColor: theme.palette.white.main,
        },
        backgroundColor: theme.palette.grey[700],
        borderRadius: `${BUTTON_BORDER_RADIUS}px`,
      },
    },
  },
})
