import { useMutation } from '@apollo/client'
import { useSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

import { UPSERT_WORKSPACE_KEY_VALUES } from './api/mutation'
import { UseUpsertWorkspaceKeyValues } from './types'

export const useUpsertWorkspaceKeyValues = (): UseUpsertWorkspaceKeyValues => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.keyValues.list.actions',
  })
  const { enqueueSnackbar } = useSnackbar()

  const [mutate] = useMutation(UPSERT_WORKSPACE_KEY_VALUES, {
    refetchQueries: ['workspaceKeyValues'],
  })

  const upsert: UseUpsertWorkspaceKeyValues['upsert'] = async ({ action, id, name }) => {
    const { data } = await mutate({ variables: { input: { workspaceKeyValueIds: [id] } } })
    const { errors } = { ...data?.upsertWorkspaceKeyValues }

    const variant: VariantType = errors && errors.length > 0 ? 'error' : 'success'

    enqueueSnackbar(t(`${action}.${variant}`, { name }), { variant })
  }

  return { upsert }
}
