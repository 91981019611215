import { WorkspaceKeyValueSliceDTO } from 'features/globalSetup/pages/GoogleAdManagerConnectionPage/api/workspaceKeyValues'
import { HB_SECTION_KEYS } from './constants'

const isKeyCreated = (key: string, workspaceKeyValue: WorkspaceKeyValueSliceDTO): boolean =>
  workspaceKeyValue?.keyValue.name.includes(key) && workspaceKeyValue?.status === 'created'

export const isSectionAvailable = (workspaceKeyValues: WorkspaceKeyValueSliceDTO[]): boolean =>
  HB_SECTION_KEYS.every((key: string) =>
    workspaceKeyValues.some((workspaceKeyValues: WorkspaceKeyValueSliceDTO) =>
      isKeyCreated(key, workspaceKeyValues),
    ),
  )
