import { useQuery } from '@apollo/client'
import { useState } from 'react'

import {
  Data,
  GET_WORKSPACE_KEY_VALUES,
  sortToDTO,
  workspaceKeyValueFromDTO,
} from 'features/globalSetup/pages/GoogleAdManagerConnectionPage/api/workspaceKeyValues'
import { WorkspaceKeyValueSlice } from '../../types'
import { UseGetWorkspaceKeyValues, UseGetWorkspaceKeyValuesProps } from './types'

export const useGetWorkspaceKeyValues = ({
  sort,
}: UseGetWorkspaceKeyValuesProps): UseGetWorkspaceKeyValues => {
  const [workspaceKeyValues, setWorkspaceKeyValues] = useState<WorkspaceKeyValueSlice[]>([])

  const { loading } = useQuery<Data>(GET_WORKSPACE_KEY_VALUES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ workspaceKeyValues }) => {
      setWorkspaceKeyValues(workspaceKeyValues.map(dto => workspaceKeyValueFromDTO(dto)))
    },
    variables: {
      sort: sortToDTO(sort),
    },
  })

  return { loading, workspaceKeyValues }
}
