import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SetupPrebidStackSectionContext } from 'features/globalSetup/contexts/SetupPrebidStackSectionContext'
import { gamSetupOrderFromDTO } from 'features/workspace/mappers/gamSetupOrderFromDTO'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { CONNECT_PREBID_STACK_TO_GAM, Data, toDTO } from './api'
import { UseConnectPrebidStackToGam } from './types'

export const useConnectPrebidStackToGam = (): UseConnectPrebidStackToGam => {
  const { setGamSetupOrder, setIsDialogOpen, setLoading } = useContext(
    SetupPrebidStackSectionContext,
  )
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.setupPrebidStack.form',
  })
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate] = useMutation(CONNECT_PREBID_STACK_TO_GAM, {
    onCompleted: ({ connectPrebidStackToGam: { errors, gamSetupOrder } }: Data): void => {
      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('notification.error'), { variant: 'error' })
      } else if (gamSetupOrder) {
        setGamSetupOrder(gamSetupOrderFromDTO(gamSetupOrder))
        enqueueSnackbar(t('notification.success'), { variant: 'success' })
      }

      setLoading(false)
      setIsDialogOpen(false)
    },
  })

  const connect = (data: Schema): void => {
    setLoading(true)

    mutate({
      variables: {
        input: toDTO(data),
      },
    })
  }

  return { connect, errors }
}
