import { useTranslation } from 'react-i18next'

import { GamConnectionOrder } from 'features/workspace/types/gamConnectionOrder'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { DATE_FORMAT, formatDate } from 'utils/date'
import { ErrorBox } from '../../../../components/ErrorBox'
import { StatusBox } from '../../../../components/StatusBox'
import { useConfig } from './useConfig'

export const ConnectionStatus = ({
  errorMessage,
  status,
  updatedAt,
}: GamConnectionOrder): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.connectionStatus',
  })

  const { color, icon, tooltip } = useConfig(status)
  const isConnectionFailed = status === 'failed'

  return (
    <>
      <StatusBox
        color={color}
        icon={icon}
        message={t(`status.${status}`, {
          updatedAt: formatDate(updatedAt, DATE_FORMAT.WITH_TIME),
        })}
        tooltip={tooltip}
      />

      {isConnectionFailed && (
        <ErrorBox
          errorMessage={errorMessage}
          sx={{ mb: CONTAINER_SPACING }}
        />
      )}
    </>
  )
}
