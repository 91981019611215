import { z } from 'zod'

import { PageForGeneralForm } from '../GeneralForm/hooks/useGetPage'

export const schema = z.object({
  customPrebidTimeoutEnabled: z.boolean(),
  id: z.string(),
  prebidTimeout: z.number(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = ({
  pagePrebidStack,
}: Pick<PageForGeneralForm, 'pagePrebidStack'>): Schema => {
  const { customPrebidTimeoutEnabled, id, prebidTimeout } = pagePrebidStack

  return {
    customPrebidTimeoutEnabled,
    id,
    prebidTimeout,
  }
}
