import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainBidderDTO } from 'features/globalSetup/api/types/domainBidderDTO'
import { DOMAIN_BIDDER_SCHEMA_FRAGMENT } from './domainBidderSchema'

export const DOMAIN_BIDDER_FRAGMENT: TypedDocumentNode<DomainBidderDTO> = gql`
  ${DOMAIN_BIDDER_SCHEMA_FRAGMENT}

  fragment DomainBidderFragment on DomainBidder {
    id
    domain {
      id
      name
    }
    priority
    csEnabled
    csSchema {
      ...DomainBidderSchemaFragment
    }
    s2sEnabled
    s2sSchema {
      ...DomainBidderSchemaFragment
    }
    workspaceBidder {
      id
      priority
      bidder {
        id
        s2sAvailable
      }
      status
    }
  }
`
