import { SvgIcon, Typography } from '@mui/material'

import { FlexBox } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { StatusBoxProps } from './types'

export const StatusBox = ({ color, icon, message, tooltip }: StatusBoxProps): JSX.Element => {
  return (
    <FlexBox
      axis='x'
      data-cy='connection-status'
      sx={{
        color,
        gap: 1,
        mb: CONTAINER_SPACING,
      }}
    >
      <>
        {icon && (
          <SvgIcon
            component={icon}
            inheritViewBox
          />
        )}

        <Typography>{message}</Typography>
      </>
      {tooltip && <Tooltip {...tooltip} />}
    </FlexBox>
  )
}
