import { CellContext, ColumnDef } from '@tanstack/react-table'
import { capitalCase } from 'change-case'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import Tooltip from 'components/Tooltip'
import { DATE_FORMAT } from 'utils/date'
import { ActionsCell } from './components/ActionsCell'
import { ChipCell } from './components/ChipCell'
import { WorkspaceKeyValueSlice } from './types'

export const useColumns = (): ColumnDef<WorkspaceKeyValueSlice>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.keyValues.list.header',
  })

  return [
    {
      accessorKey: 'keyValue.type',
      cell: ({
        row: {
          original: {
            keyValue: { type },
          },
        },
      }: CellContext<WorkspaceKeyValueSlice, unknown>): string => capitalCase(type),
      header: t('type'),
    },
    {
      accessorKey: 'keyValue.name',
      cell: ({
        row: {
          original: {
            keyValue: { name, tooltip },
          },
        },
      }: CellContext<WorkspaceKeyValueSlice, unknown>): JSX.Element => (
        <>
          {name}
          {tooltip && <Tooltip content={tooltip} />}
        </>
      ),
      header: t('name'),
    },
    {
      accessorKey: 'status',
      cell: props => <ChipCell {...props} />,
      header: t('status'),
    },
    {
      accessorKey: 'updatedAt',
      cell: (props: CellContext<WorkspaceKeyValueSlice, unknown>): JSX.Element => (
        <LastModifiedCell
          {...props}
          dateFormat={DATE_FORMAT.WITH_TIME}
        />
      ),
      header: t('updatedAt'),
    },
    {
      accessorKey: 'actions',
      cell: (props: CellContext<WorkspaceKeyValueSlice, unknown>): JSX.Element => (
        <ActionsCell {...props} />
      ),
      header: t('actions'),
      size: COLUMN_SIZE.SM,
    },
  ]
}
