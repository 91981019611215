import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, StackForm, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { CommonSchema } from './schemas/commonSchema'

export const CommonForm = () => {
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.general.common',
  })
  const { control } = useFormContext<CommonSchema>()

  const isDisabled: boolean =
    ability.cannot('create', 'GlobalSetupFeature-supplyChain') ||
    ability.cannot('update', 'GlobalSetupFeature-supplyChain')

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('header.description')}
        title={t('header.title')}
      />

      <BoxForm>
        <StackForm>
          <TextField
            control={control}
            disabled={isDisabled}
            label={t('basicAttributes.supplyChainNetwork.label')}
            name='name'
            tooltip={{
              content: t('basicAttributes.supplyChainNetwork.tooltip'),
            }}
          />
          <TextField
            control={control}
            disabled={isDisabled}
            label={t('basicAttributes.asi.label')}
            name='asi'
            tooltip={{
              content: t('basicAttributes.asi.tooltip'),
            }}
          />
          <TextField
            control={control}
            disabled={isDisabled}
            label={t('basicAttributes.sid.label')}
            name='defaultSid'
            tooltip={{
              content: t('basicAttributes.sid.tooltip'),
            }}
          />
        </StackForm>
      </BoxForm>
    </Paper>
  )
}
