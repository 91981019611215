import { mapDTO } from '@twistezo/ts-dto-mapper'

import { AdUnitDTO } from 'features/inventory/api/types/adUnitDTO'
import { domainFromDTO } from 'features/inventory/mappers/domain'
import { AdUnitForPageTransferList } from 'features/inventory/page/forms/GeneralForm/hooks/useGetAdUnitsForPageTransferList/adUnitForPageTransferList'
import { adUnitForPageTransferListFromDTO } from 'features/inventory/page/forms/GeneralForm/hooks/useGetAdUnitsForPageTransferList/api/mapper'
import { PageForGeneralForm } from '../types'
import { PageSliceDTO } from './types'

export const pageForGeneralFormFromDTO = (from: PageSliceDTO): PageForGeneralForm =>
  mapDTO<PageSliceDTO, PageForGeneralForm>({ from }).transform(pageDTO => ({
    ...pageDTO,
    adUnits: pageDTO.adUnits.map(
      (adUnit: AdUnitDTO): AdUnitForPageTransferList => adUnitForPageTransferListFromDTO(adUnit),
    ),
    domain: domainFromDTO(pageDTO.domain),
  }))
