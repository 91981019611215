import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertBox } from 'components/Box'
import { BulletPointList } from './BulletPointList'
import { ErrorBoxProps } from './types'

export const ErrorBox = ({ errorMessage, ...alertProps }: ErrorBoxProps): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection',
  })
  const [expanded, setExpanded] = useState(false)

  const ShowMore = (): JSX.Element => (
    <Button
      data-cy='error-box-alert-button'
      endIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      onClick={() => setExpanded(prev => !prev)}
      sx={{
        '&, &:hover': {
          backgroundColor: 'inherit',
        },
        color: 'error.main',
        overflow: 'hidden',
        px: 0,
      }}
    >
      <Typography>{t(`errorBox.button.${expanded ? 'showLess' : 'showMore'}`)}</Typography>
    </Button>
  )

  return (
    <AlertBox
      {...alertProps}
      severity='error'
      title={t('errorBox.title')}
    >
      {_.isArray(errorMessage) ? (
        <BulletPointList
          expanded={expanded}
          items={errorMessage}
        />
      ) : (
        <Typography noWrap={!expanded}>{errorMessage}</Typography>
      )}

      <ShowMore />
    </AlertBox>
  )
}
