import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import {
  RelatedList,
  RelatedListItem,
  RelatedSettings as RelatedSettingsRoot,
} from 'components/RelatedSettings'
import { WorkspacePrebidConfiguration } from 'features/globalSetup/types/workspacePrebidConfiguration'
import { Domain } from 'features/inventory/types/domain'
import { Workspace } from 'features/workspace'
import { ROUTES } from 'routes'

export type RelatedSettingsProps = {
  domain: {
    domainId: Domain['id']
    domainPrebidStack: Pick<
      Domain['domainPrebidStack'],
      'customPrebidTimeoutEnabled' | 'prebidTimeout'
    >
  }
  workspace: {
    workspaceId: Workspace['id']
    workspacePrebidConfiguration: Pick<WorkspacePrebidConfiguration, 'prebidTimeout'>
  }
}

export const RelatedSettings = ({
  domain: {
    domainId,
    domainPrebidStack: {
      customPrebidTimeoutEnabled: customDomainPrebidTimeoutEnabled,
      prebidTimeout: domainTimeout,
    },
  },
  workspace: {
    workspaceId,
    workspacePrebidConfiguration: { prebidTimeout: workspaceTimeout },
  },
}: RelatedSettingsProps): JSX.Element => {
  const { t } = useTranslation('features/page', {
    keyPrefix: 'form.prebidStack.form.relatedSettings',
  })

  const domainSettingsUrl: string = generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
    id: domainId,
    tab: 'prebid-stack',
    workspaceId,
  })

  const workspaceSettingsUrl: string = generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, {
    workspaceId,
  })

  return (
    <RelatedSettingsRoot>
      <RelatedList>
        {customDomainPrebidTimeoutEnabled ? (
          <RelatedListItem
            content={t('domainTimeout')}
            url={domainSettingsUrl}
            value={domainTimeout}
          />
        ) : (
          <RelatedListItem
            content={t('workspaceTimeout')}
            url={workspaceSettingsUrl}
            value={workspaceTimeout}
          />
        )}
      </RelatedList>
    </RelatedSettingsRoot>
  )
}
