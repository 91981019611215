import { SvgIconComponent } from '@mui/icons-material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { SvgIcon, Theme, Tooltip as TooltipRoot } from '@mui/material'

import { TextLink } from 'components/Link'
import { Content } from './Content'
import { TooltipProps } from './types'

export const Tooltip = ({
  children,
  content,
  link,
  placement,
  variant = 'help',
  ...svgIconProps
}: TooltipProps): JSX.Element => {
  const Icon: SvgIconComponent =
    variant === 'help' ? HelpOutlineOutlinedIcon : ErrorOutlineOutlinedIcon

  const iconColor = (theme: Theme) =>
    variant === 'help' ? theme.palette.grey[400] : theme.palette.error.main

  const IconContainer: JSX.Element = (
    <SvgIcon
      {...svgIconProps}
      component={Icon}
      fontSize='small'
      sx={{
        ...svgIconProps.sx,
        alignSelf: 'center',
        color: theme => iconColor(theme),
        ml: 1,
      }}
    />
  )

  return content ? (
    <TooltipRoot
      placement={placement ?? 'right-start'}
      title={
        <>
          {<Content content={content} />}
          {link && <TextLink link={link} />}
        </>
      }
    >
      {children || IconContainer}
    </TooltipRoot>
  ) : (
    <>{children}</>
  )
}
