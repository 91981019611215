import BugReportIcon from '@mui/icons-material/BugReport'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import { IconButton, Menu, SvgIcon, Theme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ListItemButton } from 'components/List'
import { useMenu } from 'hooks/useMenu'
import KnowledgeBaseIcon from 'images/icons/knowledge-base.svg?react'
import PortalIcon from 'images/icons/portal.svg?react'
import { URL } from './constants'

export const HelpMenu = (): JSX.Element => {
  const { t } = useTranslation('features/layout', { keyPrefix: 'helpMenu' })
  const { anchor, close, isOpen, open } = useMenu()

  const MenuElements = (): JSX.Element => (
    <>
      <ListItemButton
        icon={
          <SvgIcon
            component={KnowledgeBaseIcon}
            inheritViewBox
          />
        }
        newTab
        text={t('knowledgeBase')}
        to={URL.KNOWLEDGE_BASE}
      />
      <ListItemButton
        icon={
          <SvgIcon
            component={PortalIcon}
            inheritViewBox
          />
        }
        newTab
        text={t('portal')}
        to={URL.PORTAL}
      />
      <ListItemButton
        icon={<VerifiedOutlinedIcon />}
        newTab
        text={t('whatsNew')}
        to={URL.WHATS_NEW}
      />
      <ListItemButton
        icon={<BugReportIcon />}
        newTab
        text={t('reportBug')}
        to={URL.REPORT_BUG}
      />
    </>
  )

  return (
    <>
      <IconButton
        data-cy='menu-button-help'
        onClick={open}
        sx={(theme: Theme) => ({ color: theme.palette.grey[500] })}
      >
        <HelpOutlineOutlinedIcon />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <MenuElements />
      </Menu>
    </>
  )
}
