import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditPageContext } from '../../contexts/AddEditPageContext'
import { AuctionTimeoutSection } from './AuctionTimeoutSection'
import { Schema, schema, schemaDefault } from './schema'
import { useUpdatePagePrebidStack } from './useUpdatePagePrebidStack'

export const PrebidStackForm = (): JSX.Element => {
  const { formId, loading, page, setTouched, setValid } = useContext(AddEditPageContext)
  const { errors, update } = useUpdatePagePrebidStack()

  if (!page) {
    return <Loading />
  }

  const { pagePrebidStack } = page

  return (
    <Form<Schema>
      externalErrors={errors}
      id={formId}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault({ pagePrebidStack })}
    >
      {() => (loading ? <Loading /> : <AuctionTimeoutSection />)}
    </Form>
  )
}
