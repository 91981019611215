import { mapDTO } from '@twistezo/ts-dto-mapper'

import { Schema } from '../../schema'
import { Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const { customPrebidTimeoutEnabled, id, prebidTimeout } = data

    return {
      customPrebidTimeoutEnabled,
      id,
      prebidTimeout,
    }
  })
