import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_WORKSPACE_KEY_VALUES: TypedDocumentNode<Data, Variables> = gql`
  query workspaceKeyValues($sort: WorkspaceKeyValueSortInput) {
    workspaceKeyValues(sort: $sort) {
      errorMessage
      id
      keyValue {
        id
        name
        tooltip
        type
      }
      status
      updatedAt
    }
  }
`
