import { gql, TypedDocumentNode } from '@apollo/client'

import { DOMAIN_FRAGMENT } from 'features/inventory/api/fragments/domain'
import { AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT } from 'features/inventory/page/forms/GeneralForm/hooks/useGetAdUnitsForPageTransferList/api/fragment'
import { PageSliceDTO } from 'features/inventory/page/forms/GeneralForm/hooks/useGetPage/api/types'

export const PAGE_FRAGMENT: TypedDocumentNode<PageSliceDTO> = gql`
  ${AD_UNIT_FOR_PAGE_TRANSFER_LIST_FRAGMENT}
  ${DOMAIN_FRAGMENT}

  fragment PageFragment on Page {
    adUnits {
      ...AdUnitForPageTransferList
    }
    createdAt
    domain {
      ...DomainFragment
    }
    id
    matchType
    name
    path
    pagePrebidStack {
      id
      customPrebidTimeoutEnabled
      prebidTimeout
    }
    updatedAt
  }
`
