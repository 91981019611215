import { SortInput } from 'components/Table'
import { SortBy } from './hooks/useGetDomainPrebidModules/types'

export const KNOWLEDGE_BASE_URL = {
  ANALYTICS: 'https://knowledge-base.yieldbird.com/prebid-stack/prebid-analytics-adapters',
  GENERAL: 'https://knowledge-base.yieldbird.com/prebid-stack/general-modules',
  USER_ID: 'https://knowledge-base.yieldbird.com/prebid-stack/user-id-module',
  VENDOR_SPECIFIC:
    'https://knowledge-base.yieldbird.com/prebid-stack/vendor-specific-prebid-modules',
} as const

export const DEFAULT_SORT: SortInput<SortBy> = {
  by: 'prebidModule.name',
  direction: 'desc',
} as const

export const SORTABLE_COLUMNS: SortBy[] = ['enabled', 'prebidModule.name', 'updatedAt']
