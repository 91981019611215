import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddEditPageContext } from 'features/inventory/page/contexts/AddEditPageContext'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../schema'
import { toDTO } from './api/mapper'
import { UPDATE_PAGE_PREBID_STACK } from './api/mutation'
import { Data } from './api/types'
import { UseUpdatePagePrebidStack } from './types'

export const useUpdatePagePrebidStack = (): UseUpdatePagePrebidStack => {
  const { t } = useTranslation('features/page', {
    keyPrefix: 'form.prebidStack.form.update',
  })
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(AddEditPageContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const [mutate, { loading }] = useMutation(UPDATE_PAGE_PREBID_STACK, {
    onCompleted: ({ updatePagePrebidStack }: Data): void => {
      if (updatePagePrebidStack) {
        const { errors } = updatePagePrebidStack

        if (errors && errors.length > 0) {
          setErrors(mapGraphQLErrors(errors))
          enqueueSnackbar(t('error'), { variant: 'error' })
        } else {
          setErrors({})
          enqueueSnackbar(t('success'), { variant: 'success' })
        }
      }
    },
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const update = (data: Schema): void => {
    mutate({
      variables: {
        input: toDTO(data),
      },
    })
  }

  return { errors, update }
}
