import { CellContext } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'

import { TextField } from 'components/Form'
import { useAbility } from 'providers/casl'
import { Schema } from '../../../form/schema'
import { WorkspaceBidderForTable } from '../../../hooks/useGetBidders/types'

export const SidInputCell = ({
  cell: { getValue },
  row: {
    original: { id },
  },
}: CellContext<WorkspaceBidderForTable, unknown>) => {
  const ability = useAbility()
  const { control } = useFormContext<Schema>()

  return (
    <TextField
      control={control}
      disabled={ability.cannot('update', 'GlobalSetupFeature-supplyChain')}
      fullWidth
      name={`workspaceBidderSids.${id}`}
      value={getValue()}
    />
  )
}
