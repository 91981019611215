import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainBidderSlice } from '../types'
import { DomainBidderSliceDTO } from './types'
import { mapParametersSchema } from './utils'

type From = DomainBidderSliceDTO
type To = DomainBidderSlice

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((domainBidder: From): To => {
    const { csSchema, s2sSchema } = domainBidder

    return {
      ...domainBidder,
      csParamsSchema: mapParametersSchema(csSchema),
      s2sParamsSchema: mapParametersSchema(s2sSchema),
    }
  })
