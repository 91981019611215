import { List, ListItem as ListItemRoot, Typography } from '@mui/material'
import { Trans } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { BulletPointListProps } from './types'

export const BulletPointList = ({ expanded, items }: BulletPointListProps): JSX.Element => {
  const translationComponents = {
    bold: (
      <Typography
        fontWeight='bold'
        px={1}
        sx={{ minWidth: 'fit-content' }}
      />
    ),
    span: <Typography noWrap={!expanded} />,
  }

  const ListItem = ({ text }: { text: string }): JSX.Element => (
    <ListItemRoot
      disableGutters
      sx={{ display: 'list-item' }}
    >
      <FlexBox
        axis='x'
        sx={{ alignItems: 'flex-start' }}
      >
        <Trans components={translationComponents}>{text}</Trans>
      </FlexBox>
    </ListItemRoot>
  )

  return (
    <List
      dense
      disablePadding
      sx={{
        listStyleType: 'disc',
        ml: 2,
      }}
    >
      {items.map(
        (text: string): JSX.Element => (
          <ListItem
            key={text}
            text={text}
          />
        ),
      )}
    </List>
  )
}
