import { gql, TypedDocumentNode } from '@apollo/client'

import { PAGE_FRAGMENT } from 'features/inventory/page/forms/GeneralForm/hooks/useGetPage/api/fragment'
import { Data, Variables } from './types'

export const GET_PAGE: TypedDocumentNode<Data, Variables> = gql`
  ${PAGE_FRAGMENT}

  query page($id: ID!) {
    node(id: $id) {
      ...PageFragment
    }
  }
`
