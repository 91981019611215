import { Chip, CSSObject, Theme } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { sentenceCase } from 'change-case'

import { WorkspaceKeyValueSlice } from '../../types'

export const ChipCell = ({
  row: {
    original: { status },
  },
}: CellContext<WorkspaceKeyValueSlice, unknown>) => {
  const chipColor = (theme: Theme): CSSObject['backgroundColor'] => {
    switch (status) {
      case 'created':
        return theme.palette.success.main
      case 'inProgress':
        return theme.palette.warning.main
      case 'notCreated':
        return theme.palette.grey[500]
    }
  }

  return (
    <Chip
      label={sentenceCase(status)}
      size='small'
      sx={(theme: Theme): CSSObject => ({
        backgroundColor: chipColor(theme),
      })}
      variant='filledWhiteText'
    />
  )
}
