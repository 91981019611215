import { useQuery } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'

import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { gamSetupOrderFromDTO } from 'features/workspace/mappers/gamSetupOrderFromDTO'
import { GamSetupOrder } from 'features/workspace/types/gamSetupOrder'
import { GET_GAM_SETUP_ORDER } from './api/query'
import { UseGetGamSetupOrder } from './types'

export const useGetGamSetupOrder = (
  setGamSetupOrder: Dispatch<SetStateAction<GamSetupOrder | undefined>>,
): UseGetGamSetupOrder => {
  const { workspaceId: id } = useWorkspaceParam()

  const { loading } = useQuery(GET_GAM_SETUP_ORDER, {
    onCompleted: ({ node: { gamSetupOrder } }) => {
      if (gamSetupOrder) {
        setGamSetupOrder(gamSetupOrderFromDTO(gamSetupOrder))
      }
    },
    variables: { id },
  })

  return { loading }
}
