import _ from 'lodash'

import { AdUnitParameter, AdUnitParameters } from 'features/globalSetup/types/adUnitBidder'
import { Schema } from '../../form/schema'
import { AdUnitBidderSlice } from '../../hooks/UseGetAdUnitBidders'

export const adUnitBiddersToSchema = (
  adUnitBidders: AdUnitBidderSlice[],
): Schema['adUnitBidders'] =>
  _.chain(adUnitBidders)
    .keyBy('id')
    .mapValues((adUnitBidder: AdUnitBidderSlice) => {
      const { csEnabled, csParams, s2sEnabled, s2sParams } = adUnitBidder

      return {
        csDisabledByRequiredParams: false,
        csEnabled,
        csParams: _.mapValues(csParams, 'value'),
        s2sDisabledByRequiredParams: false,
        s2sEnabled,
        s2sParams: _.mapValues(s2sParams, 'value'),
      }
    })
    .value()

export const getRequiredParamsNames = (paramsSchema: AdUnitParameters): AdUnitParameter['name'][] =>
  Object.entries(paramsSchema)
    .filter(
      ([, { required, requiredOff }]: [key: keyof AdUnitParameters, value: AdUnitParameter]):
        | boolean
        | undefined => (requiredOff ? false : required),
    )
    .map(
      ([key]: [key: keyof AdUnitParameters, value: AdUnitParameter]): AdUnitParameter['name'] =>
        key,
    )
