import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceDTO } from '..'
import { Workspace } from '../types/workspace'
import { csManagerFromDTO } from './csManager'
import { dataSamplingFromDTO } from './dataSampling'
import { gamConnectionOrderFromDTO } from './gamConnectionOrderFromDTO'
import { gamSetupOrderFromDTO } from './gamSetupOrderFromDTO'

export const workspaceFromDTO = (from: WorkspaceDTO): Workspace =>
  mapDTO<WorkspaceDTO, Workspace>({ from }).transform(workspaceDTO => {
    const {
      createdAt,
      csManager,
      financeReportUrl,
      gamSetupOrder,
      latestGamConnectionOrder,
      prebidManagerAccountId,
      prebidManagerSampling,
      primaryCurrency,
      updatedAt,
    } = workspaceDTO

    return {
      ...workspaceDTO,
      createdAt: new Date(createdAt),
      csManager: csManager ? csManagerFromDTO(csManager) : undefined,
      financeReportUrl: financeReportUrl ?? undefined,
      gamSetupOrder: gamSetupOrder ? gamSetupOrderFromDTO(gamSetupOrder) : undefined,
      latestGamConnectionOrder: latestGamConnectionOrder
        ? gamConnectionOrderFromDTO(latestGamConnectionOrder)
        : undefined,
      prebidManagerAccountId: prebidManagerAccountId ?? undefined,
      prebidManagerSampling: dataSamplingFromDTO(prebidManagerSampling),
      primaryCurrency: primaryCurrency ?? undefined,
      updatedAt: new Date(updatedAt),
    }
  })
