import { Can } from 'providers/casl'

export const onGlobalSetupAmazonPages = (can: Can) => {
  can('access', 'GlobalSetupPage-amazon')
  can('access', 'GlobalSetupPage-amazon-general')
  can('access', 'GlobalSetupPage-amazon-inventory-connection')
}

export const onGlobalSetupAmazonUpdate = (can: Can) => {
  can('update', 'GlobalSetupFeature-amazon')
}

export const onGlobalSetupAmazonYieldbirdAccountStatus = (can: Can) => {
  can('update', 'GlobalSetupFeature-amazon-yieldbird-account-status')
}
