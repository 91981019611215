import { Box, SxProps, TableCell, Theme } from '@mui/material'
import { Link, To, useNavigate } from 'react-router-dom'

import { cellWrapperStyles } from '../cellWrapperStyles'
import { CellProps, LinkSyncProps } from './types'
import { useCell } from './useCell'

export const Cell = <T,>({ cell, onClickRow, onClickRowAsync }: CellProps<T>): JSX.Element => {
  const { Content, isClickable, row, styles } = useCell({ cell })
  const navigate = useNavigate()

  const commonSX = ({ cursorPointer }: { cursorPointer?: boolean } = {}): SxProps<Theme> => ({
    ...cellWrapperStyles<T>(cell.column.columnDef.meta),
    ...(cursorPointer && { cursor: 'pointer' }),
  })

  const handleOnClickAsync = (): void => {
    if (onClickRowAsync) {
      Promise.resolve(onClickRowAsync(row.original)).then((to: To | void): void => {
        if (to) navigate(to)
      })
    }
  }

  const LinkSync = ({ onClickRow }: LinkSyncProps<T>): JSX.Element => (
    <Box
      component={Link}
      sx={commonSX({ cursorPointer: true })}
      to={onClickRow(row.original)}
    >
      {Content}
    </Box>
  )

  const LinkAsync = (): JSX.Element => (
    <Box
      onClick={handleOnClickAsync}
      sx={commonSX({ cursorPointer: true })}
    >
      {Content}
    </Box>
  )

  const Default = (): JSX.Element => <Box sx={commonSX()}>{Content}</Box>

  const ContentWrapper = (): JSX.Element => {
    if (isClickable) {
      if (onClickRow !== undefined) {
        return <LinkSync onClickRow={onClickRow} />
      } else if (onClickRowAsync !== undefined) {
        return <LinkAsync />
      }
    }

    return <Default />
  }

  return (
    <TableCell
      data-cy={`table-cell-${cell.id}`}
      key={cell.id}
      sx={styles}
    >
      {ContentWrapper()}
    </TableCell>
  )
}
