import { Edit, MoreVert } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ListItemButton } from 'components/List'
import { useMenu } from 'hooks/useMenu'

export const ActionsCell = (): JSX.Element => {
  const { t } = useTranslation('common')
  const { anchor, close, isOpen, open } = useMenu()

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      icon={<Edit />}
      onClick={() => void {}}
      text={t('form.action.edit')}
    />
  )

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <EditItem key='edit' />
      </Menu>
    </>
  )
}
