import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { pageForGeneralFormFromDTO } from './api/mapper'
import { GET_PAGE } from './api/query'
import { PageForGeneralForm, UseGetPage, UseGetPageProps } from './types'

export const useGetPage = ({ id }: UseGetPageProps): UseGetPage => {
  const [page, setPage] = useState<PageForGeneralForm>()

  useEffect(() => {
    if (id) {
      queryPage({
        variables: { id },
      })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const [queryPage, { loading }] = useLazyQuery(GET_PAGE, {
    onCompleted: ({ node: page }) => {
      if (page) {
        setPage(pageForGeneralFormFromDTO(page))
      }
    },
  })

  return { loading, page }
}
