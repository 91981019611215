const BASE = {
  HOME: '/home',
  NOT_FOUND: '/404',
  ROOT: '/',
} as const

const AUTH = {
  COMPLETE_SETUP: '/register/:invitationToken',
  CONFIRM_RESET_PASSWORD: '/reset-password/:resetPasswordToken',
  LOGIN: '/login',
  REQUEST_RESET_PASSWORD: '/request-reset-password',
} as const

const USER = {
  EMPLOYEE: {
    ADD: '/users/employees/add',
    EDIT: '/users/employees/edit/:id',
  },
  LIST: '/users/:tab',
  PUBLISHER: {
    ADD: '/users/publishers/add',
    EDIT: '/users/publishers/edit/:id',
  },
} as const

const WORKSPACE = {
  ADD: '/workspaces/add',
  EDIT: '/workspaces/edit/:id',
  LIST: '/workspaces',
} as const

export const WORKSPACE_PREFIX = '/workspaces/:workspaceId'
export const GLOBAL_SETUP_PREBID_PREFIX = WORKSPACE_PREFIX + '/global-setup/prebid'

const PERFORMANCE = {
  PREBID_STACK: {
    ANALYTICS: WORKSPACE_PREFIX + '/performance/prebid-stack/analytics/:tab',
  },
  PRODUCT: WORKSPACE_PREFIX + '/performance/:product',
  ROOT: WORKSPACE_PREFIX + '/performance',
} as const

const FINANCE = WORKSPACE_PREFIX + '/finance'

const PRODUCT = {
  LIST: WORKSPACE_PREFIX + '/products',
} as const

const INVENTORY = {
  AD_UNIT: {
    ADD: WORKSPACE_PREFIX + '/inventory/ad-units/add',
    EDIT: WORKSPACE_PREFIX + '/inventory/ad-units/edit/:id/:tab',
    LIST: WORKSPACE_PREFIX + '/inventory/ad-units',
  },

  DOMAIN: {
    ADD: WORKSPACE_PREFIX + '/inventory/domains/add',
    EDIT: WORKSPACE_PREFIX + '/inventory/domains/edit/:id/:tab',
    LIST: WORKSPACE_PREFIX + '/inventory/domains',
  },

  PAGE: {
    ADD: WORKSPACE_PREFIX + '/inventory/pages/add',
    EDIT: WORKSPACE_PREFIX + '/inventory/pages/edit/:id/:tab',
    LIST: WORKSPACE_PREFIX + '/inventory/pages',
  },

  VIEWPORT: WORKSPACE_PREFIX + '/inventory/viewports',
} as const

const GLOBAL_SETUP = {
  AMAZON: {
    ROOT: WORKSPACE_PREFIX + '/global-setup/amazon/:tab',
  },
  GAM_CONNECTION: {
    ROOT: WORKSPACE_PREFIX + '/global-setup/gam-connection',
  },
  GENERAL: {
    ROOT: WORKSPACE_PREFIX + '/global-setup/general/:tab',
    USER: {
      ADD: WORKSPACE_PREFIX + '/global-setup/general/user-management/add',
      EDIT: WORKSPACE_PREFIX + '/global-setup/general/user-management/edit/:id',
    },
  },
  PREBID: {
    BIDDERS: {
      ADD: GLOBAL_SETUP_PREBID_PREFIX + '/bidders/add',
      EDIT: GLOBAL_SETUP_PREBID_PREFIX + '/bidders/edit/:id/:tab',
      EDIT_SPECIFIC:
        GLOBAL_SETUP_PREBID_PREFIX + '/bidders/edit/:id/:tab/:domainId/:domainBidderId',
      ROOT: GLOBAL_SETUP_PREBID_PREFIX + '/bidders',
    },
    DEFAULTS: {
      ROOT: GLOBAL_SETUP_PREBID_PREFIX + '/defaults/',
    },
    MODULES: {
      ANALYTICS: {
        ADD: GLOBAL_SETUP_PREBID_PREFIX + '/modules/analytics/add',
        EDIT: GLOBAL_SETUP_PREBID_PREFIX + '/modules/analytics/edit/:id/:tab',
        LIST: GLOBAL_SETUP_PREBID_PREFIX + '/modules/analytics',
      },
      GENERAL: {
        EDIT: GLOBAL_SETUP_PREBID_PREFIX + '/modules/general/edit/:id/:tab',
        LIST: GLOBAL_SETUP_PREBID_PREFIX + '/modules/general',
      },
      ROOT: GLOBAL_SETUP_PREBID_PREFIX + '/modules/:tab',
      USER_ID: {
        ADD: GLOBAL_SETUP_PREBID_PREFIX + '/modules/userId/add',
        EDIT: GLOBAL_SETUP_PREBID_PREFIX + '/modules/userId/edit/:id/:tab',
        LIST: GLOBAL_SETUP_PREBID_PREFIX + '/modules/userId',
      },
      VENDOR_SPECIFIC: {
        ADD: GLOBAL_SETUP_PREBID_PREFIX + '/modules/vendorSpecific/add',
        EDIT: GLOBAL_SETUP_PREBID_PREFIX + '/modules/vendorSpecific/edit/:id/:tab',
        LIST: GLOBAL_SETUP_PREBID_PREFIX + '/modules/vendorSpecific',
      },
    },
    PROTECTION: {
      ADD: GLOBAL_SETUP_PREBID_PREFIX + '/protection/add',
      EDIT: GLOBAL_SETUP_PREBID_PREFIX + '/protection/edit/:id',
      LIST: GLOBAL_SETUP_PREBID_PREFIX + '/protection',
    },
  },
  SCHAIN: {
    ADD: WORKSPACE_PREFIX + '/global-setup/general/supply-chains/add',
    EDIT: WORKSPACE_PREFIX + '/global-setup/general/supply-chains/edit/:id/:tab',
    LIST: WORKSPACE_PREFIX + '/global-setup/general/supply-chains',
  },
} as const

const TAG = WORKSPACE_PREFIX + '/download-tags'

export const ROUTES = {
  AUTH,
  BASE,
  FINANCE,
  GLOBAL_SETUP,
  INVENTORY,
  PERFORMANCE,
  PRODUCT,
  TAG,
  USER,
  WORKSPACE,
} as const
