import { gql, TypedDocumentNode } from '@apollo/client'

import { GAM_SETUP_ORDER_FRAGMENT } from 'features/workspace/api/fragments/gamSetupOrder'
import { ERROR_FRAGMENT } from 'providers/graphql'
import { Data, Variables } from './types'

export const CONNECT_PREBID_STACK_TO_GAM: TypedDocumentNode<Data, { input: Variables }> = gql`
  ${GAM_SETUP_ORDER_FRAGMENT}
  ${ERROR_FRAGMENT}

  mutation connectPrebidStackToGam($input: ConnectPrebidStackToGamInput!) {
    connectPrebidStackToGam(input: $input) {
      gamSetupOrder {
        ...GamSetupOrderFragment
      }

      errors {
        ...ErrorFragment
      }
    }
  }
`
