import { User } from 'features/user'
import { Can } from '../Can'
import { onGlobalSetupAmazonUpdate } from './features/globalSetup/amazon'
import { onPageAddAccess, onPageCreateUpdateDelete } from './features/page'
import { onAdUnit, onDomain, onFinance, onGlobalSetupWorkspace, onProduct } from './userByGroup'
import {
  onAdUnitMediaTypes,
  onDomainAdvancedSettings,
  onDomainBulkManagement,
  onDomainCustomCode,
  onGlobalSetupBiddersBidParamsCommon,
  onGlobalSetupBiddersBidParamsOwn,
  onGlobalSetupBiddersCreateUpdateDelete,
  onGlobalSetupBiddersOwn,
  onGlobalSetupBiddersThirdParty,
  onGlobalSetupBiddersYieldbird,
  onGlobalSetupGoogleAdManagerConnectionUpdate,
  onGlobalSetupPrebidBiddersAdd,
  onGlobalSetupPrebidDefaults,
  onGlobalSetupPrebidModule,
  onGlobalSetupPrebidModulesParamsOwn,
  onGlobalSetupProtectionCUD,
  onGlobalSetupSupplyChainAddAccess,
  onGlobalSetupSupplyChainCreateUpdateDelete,
  onGlobalSetupWorkspaceUser,
  onViewport,
} from './userByRole'

const onViewer = (can: Can) => {
  can('access', 'DomainPage-list')
  can('access', 'AdUnitPage-list')
  can('access', 'PagePage-list')
}

export const userByWorkspaceRole = (user: User, can: Can) => {
  switch (user.currentWorkspaceRole) {
    case 'owner': {
      onAdUnit(can)
      onAdUnitMediaTypes(can)
      onDomain(can)
      onDomainAdvancedSettings(can)
      onDomainBulkManagement(can)
      onDomainCustomCode(can)
      onFinance(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupBiddersBidParamsCommon(can)
      onGlobalSetupBiddersBidParamsOwn(can)
      onGlobalSetupBiddersCreateUpdateDelete(can)
      onGlobalSetupBiddersOwn(can)
      onGlobalSetupBiddersThirdParty(can)
      onGlobalSetupBiddersYieldbird(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAdd(can)
      onGlobalSetupPrebidDefaults(can)
      onGlobalSetupPrebidModule(can)
      onGlobalSetupPrebidModulesParamsOwn(can)
      onGlobalSetupProtectionCUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupSupplyChainCreateUpdateDelete(can)
      onGlobalSetupWorkspace(can)
      onGlobalSetupWorkspaceUser(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onProduct(can)
      onViewport(can)
      break
    }

    case 'editor': {
      onAdUnit(can)
      onAdUnitMediaTypes(can)
      onDomain(can)
      onDomainAdvancedSettings(can)
      onDomainBulkManagement(can)
      onDomainCustomCode(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupBiddersBidParamsCommon(can)
      onGlobalSetupBiddersBidParamsOwn(can)
      onGlobalSetupBiddersCreateUpdateDelete(can)
      onGlobalSetupBiddersOwn(can)
      onGlobalSetupBiddersThirdParty(can)
      onGlobalSetupBiddersYieldbird(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAdd(can)
      onGlobalSetupPrebidDefaults(can)
      onGlobalSetupPrebidModule(can)
      onGlobalSetupPrebidModulesParamsOwn(can)
      onGlobalSetupProtectionCUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupSupplyChainCreateUpdateDelete(can)
      onGlobalSetupWorkspace(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onProduct(can)
      onViewport(can)
      break
    }

    case 'viewer': {
      onViewer(can)
      break
    }
  }
}
