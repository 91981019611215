import { Box } from '@mui/material'
import _ from 'lodash'
import { useContext } from 'react'
import { To } from 'react-router-dom'

import { useMergeFormData } from 'components/Form'
import { Table } from 'components/Table'
import { useSort } from 'components/Table/Sort'
import { canUpdate } from 'features/globalSetup'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { DomainPrebidModulesAttributesSchema, Schema } from '../../../../schema'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { useGetDomainPrebidModules } from './hooks/useGetDomainPrebidModules'
import { DomainPrebidModuleSlice, SortBy } from './hooks/useGetDomainPrebidModules/types'
import { prebidModulePathGenerator } from './prebidModulePathGenerator'
import { ModulesTableProps } from './types'
import { useColumns } from './useColumns'

export const ModuleTable = ({ bottomSpacing, moduleType }: ModulesTableProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { domain } = useContext(AddEditDomainContext)

  if (!domain) {
    throw new Error('Domain should be accessible')
  }

  const columns = useColumns(moduleType)
  const { props: sortProps, sort } = useSort<DomainPrebidModuleSlice, SortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )
  const { loading, modules } = useGetDomainPrebidModules({ domainId: domain.id, moduleType, sort })

  const domainPrebidModulesAttributes: DomainPrebidModulesAttributesSchema['domainPrebidModulesAttributes'] =
    _.chain(modules).keyBy('id').mapValues('enabled').value()

  useMergeFormData<Schema>({
    data: domainPrebidModulesAttributes,
    path: 'domainPrebidModulesAttributes',
  })

  if (modules && modules.length === 0) {
    return <></>
  }

  const handleRowClick = async (row: DomainPrebidModuleSlice): Promise<To | void> => {
    const {
      prebidModule: { account },
      workspacePrebidModule: { id },
    } = row

    if (!canUpdate({ ability, account, moduleType })) {
      return undefined
    } else {
      return prebidModulePathGenerator({ id, moduleType, workspaceId })
    }
  }

  return (
    <Box sx={{ mb: bottomSpacing ? CONTAINER_SPACING : 0 }}>
      <Table
        columns={columns}
        data={modules}
        data-cy={`domain-modules-${moduleType}-table`}
        loading={loading}
        onClickRowAsync={handleRowClick}
        sort={sortProps}
      />
    </Box>
  )
}
