import { gql, TypedDocumentNode } from '@apollo/client'

import { WorkspaceDTO } from 'features/workspace'
import { GAM_SETUP_ORDER_FRAGMENT } from 'features/workspace/api/fragments/gamSetupOrder'

export const GET_GAM_SETUP_ORDER: TypedDocumentNode<{ node: WorkspaceDTO }> = gql`
  ${GAM_SETUP_ORDER_FRAGMENT}

  query getWorkspaceForGamSetupOrder($id: ID!) {
    node(id: $id) {
      ... on Workspace {
        id
        gamSetupOrder {
          ...GamSetupOrderFragment
        }
      }
    }
  }
`
