import { Delete, Edit, MoreVert } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ListItemButton } from 'components/List'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { AdUnit } from 'providers/casl/types/subjects/adUnit'
import { Domain } from 'providers/casl/types/subjects/domain'
import { Page } from 'providers/casl/types/subjects/page'
import { ProductsItems } from './ProductsItems'
import { ActionsCellProps } from './types'

export const ProductsActionsCell = ({
  editPath,
  elementName,
  feature,
  i18nResource,
  id,
  loading,
  onDelete,
  products,
}: ActionsCellProps): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t: tc } = useTranslation('common')
  const navigate = useNavigate()
  const { anchor, close, isOpen, open } = useMenu()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  type ProductsSubject = AdUnit | Domain | Page
  const abilitySubject: ProductsSubject = _.upperFirst(feature) as ProductsSubject

  const navigateToEditGeneral = (): void =>
    navigate(
      generatePath(editPath, {
        id,
        tab: 'general',
        workspaceId,
      }),
    )

  const handleDelete = (): void => {
    close()
    setIsDialogOpen(true)
  }

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      icon={<Edit />}
      onClick={navigateToEditGeneral}
      text={tc('form.action.edit')}
    />
  )

  const DeleteItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-delete'
      icon={<Delete />}
      onClick={handleDelete}
      text={tc('form.action.delete')}
    />
  )

  const canAccessEdit: boolean = ability.can(
    'access',
    `${abilitySubject}Page-edit` as ProductsSubject,
  )
  const canDelete: boolean = ability.can('delete', abilitySubject)

  const MenuElements = (): JSX.Element => (
    <>
      {canAccessEdit && <EditItem />}

      <ProductsItems
        editPath={editPath}
        id={id}
        products={products}
      />

      {canDelete && [
        <Divider
          key='divider'
          sx={{ my: 1 }}
        />,
        <DeleteItem key='delete' />,
      ]}
    </>
  )

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <MenuElements />
      </Menu>

      <DeleteDialog
        dialog={{ isOpen: isDialogOpen, setOpen: setIsDialogOpen }}
        elementName={elementName}
        i18nResource={i18nResource}
        loading={loading}
        onDelete={onDelete}
      />
    </>
  )
}
