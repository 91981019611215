import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Data,
  GET_WORKSPACE_KEY_VALUES,
} from 'features/globalSetup/pages/GoogleAdManagerConnectionPage/api/workspaceKeyValues'
import { OPACITY_BOX_STYLE } from './constants'
import { UseGetSectionAvailability } from './types'
import { isSectionAvailable } from './utils'

export const useGetSectionAvailability = (): UseGetSectionAvailability => {
  const { t } = useTranslation(['features/globalSetup', 'common'], {
    keyPrefix: 'googleAdManagerConnection.setupPrebidStack',
  })
  const [available, setAvailable] = useState<boolean>(false)

  useQuery<Data>(GET_WORKSPACE_KEY_VALUES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ workspaceKeyValues }) => {
      setAvailable(isSectionAvailable(workspaceKeyValues))
    },
  })

  return {
    available,
    styles: available ? {} : OPACITY_BOX_STYLE,
    tooltip: available ? '' : t('tooltip'),
  }
}
