import { gql, TypedDocumentNode } from '@apollo/client'

import { PagePrebidStackSliceDTO } from './types'

export const PAGE_PREBID_STACK_FRAGMENT: TypedDocumentNode<PagePrebidStackSliceDTO> = gql`
  fragment PagePrebidStackFragment on PagePrebidStack {
    id
    customPrebidTimeoutEnabled
    prebidTimeout
  }
`
