import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { ConnectionStatus } from './components/ConnectionStatus'
import { KNOWLEDGE_BASE } from './constants'
import { useCreateConnection } from './hooks/useCreateConnection'
import { ConnectionStatusSectionProps } from './types'

export const ConnectionStatusSection = ({
  connection,
  setConnection,
}: ConnectionStatusSectionProps): JSX.Element => {
  const ability = useAbility()
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection',
  })

  const { create, loading: loadingCreatedConnection } = useCreateConnection(setConnection)

  const createDisabled =
    loadingCreatedConnection ||
    (connection && connection.status !== 'failed') ||
    ability.cannot('update', 'GlobalSetupFeature-googleAdManagerConnection-connect')

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('connectionStatus.header.description')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: tC('learnMore'),
              url: KNOWLEDGE_BASE,
            }}
          />
        }
        title={t('connectionStatus.header.title')}
      />

      {connection && <ConnectionStatus {...connection} />}

      <Button
        disabled={createDisabled}
        onClick={create}
        variant='contained'
      >
        {t('connectionStatus.button')}
      </Button>
    </Paper>
  )
}
