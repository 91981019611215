import { MoreVert } from '@mui/icons-material'
import CachedIcon from '@mui/icons-material/Cached'
import { IconButton, Menu, SvgIcon } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { ListItemButton } from 'components/List'
import { useMenu } from 'hooks/useMenu'
import CreateInGam from 'images/icons/create-in-gam.svg?react'
import { useAbility } from 'providers/casl'
import { useUpsertWorkspaceKeyValues } from '../../hooks/useUpsertWorkspaceKeyValues'
import { WorkspaceKeyValueSlice } from '../../types'

export const ActionsCell = ({
  row: {
    original: {
      id,
      keyValue: { name },
      status,
    },
  },
}: CellContext<WorkspaceKeyValueSlice, unknown>): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.keyValues.list.actionsCell',
  })
  const ability = useAbility()
  const { anchor, close, isOpen, open } = useMenu()
  const { upsert } = useUpsertWorkspaceKeyValues()

  const CreateItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-create'
      icon={
        <SvgIcon
          component={CreateInGam}
          inheritViewBox
        />
      }
      onClick={(): void => void upsert({ action: 'create', id, name })}
      text={t('create')}
    />
  )

  const UpdateItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-update'
      icon={<CachedIcon />}
      onClick={(): void => void upsert({ action: 'update', id, name })}
      text={t('update')}
    />
  )

  return (
    <>
      <IconButton
        disabled={
          ability.cannot('update', 'GlobalSetupFeature-googleAdManagerConnection-connect') ||
          status === 'inProgress'
        }
        onClick={open}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        {status === 'notCreated' ? <CreateItem /> : <UpdateItem />}
      </Menu>
    </>
  )
}
