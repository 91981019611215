import { mapDTO } from '@twistezo/ts-dto-mapper'
import { isEmpty } from 'lodash'

import { Schema } from '../../../schema'
import { Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((schema: From): To => {
    const { bannerTemplateId, nativeEnabled, nativeTemplateId, workspaceId } = schema

    return {
      bannerTemplateId: isEmpty(bannerTemplateId) ? null : Number(bannerTemplateId),
      nativeEnabled,
      nativeTemplateId: isEmpty(nativeTemplateId) ? null : Number(nativeTemplateId),
      workspaceId,
    }
  })
