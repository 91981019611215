import { authorizeGroupRole, User } from 'features/user'
import { UserGroup } from 'features/user/types/UserGroupRoles'
import { Can } from '../Can'
import { onGlobalSetupAmazonPages } from './features/globalSetup/amazon'
import { onPageListEditAccess } from './features/page'

const onAdUnitFields = (can: Can) => {
  can('read', 'AdUnitField-rawId')
  can('read', 'AdUnitField-unfilledRecovery')
}

const onDomainOverridenByYieldbirdRead = (can: Can) => {
  can('read', 'DomainField-overriddenByYieldbird')
}

const onEveryone = (can: Can) => {
  can('access', 'Layout')
  can('access', 'ProductPage-list')
  can('access', 'ViewportPage')
  can('access', 'PerformancePage')
  can('access', 'GlobalSetupPage-general')
  can('access', 'GlobalSetupPage-prebid-protection-list')
  can('access', 'GlobalSetupPage-prebid-protection-edit')
  can('access', 'TagPage-download')
  onGlobalSetupSupplyChainListEditAccess(can)
}

export const onFinance = (can: Can) => {
  can('access', 'FinancePage')
}

const onFinanceAdminOption = (can: Can) => {
  can('read', 'Finance-adminOption')
  can('update', 'Finance-adminOption')
}

export const onGlobalSetupGoogleAdManagerConnection = (can: Can) => {
  can('access', 'GlobalSetupPage-googleAdManagerConnection')
}

const onGlobalSetupPrebidProtection = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-protection-list')
  can('access', 'GlobalSetupPage-prebid-protection-edit')
}

const onGlobalSetupPrebidDefaults = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-defaults')
}

const onGlobalSetupPrebidBidders = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-bidders')
  can('access', 'GlobalSetupPage-prebid-bidders-edit')
}

const onGlobalSetupPrebidModule = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-modules')
  can('access', 'GlobalSetupPage-prebid-modules-user-ids-add')
  can('access', 'GlobalSetupPage-prebid-modules-user-ids-edit')
  can('access', 'GlobalSetupPage-prebid-modules-vendor-specific-add')
  can('access', 'GlobalSetupPage-prebid-modules-vendor-specific-edit')
  can('access', 'GlobalSetupPage-prebid-modules-analytics-add')
  can('access', 'GlobalSetupPage-prebid-modules-analytics-edit')
}

const onGlobalSetupPriceGenius = (can: Can) => {
  can('read', 'GlobalSetupField-priceGenius')
}

const onGlobalSetupSupplyChainListEditAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-supplyChain-list')
  can('access', 'GlobalSetupPage-supplyChain-edit')
}

const onPerformanceAdminOption = (can: Can) => {
  can('read', 'Performance-adminOption')
  can('update', 'Performance-adminOption')
}

const onProductStatus = (can: Can) => {
  can('read', 'ProductField-status')
  can('update', 'ProductField-status')
}

const onUser = (can: Can) => {
  can('access', 'UserPage-list')
}

const onWorkspace = (can: Can) => {
  can('access', 'WorkspacePage-list')
  can('access', 'WorkspacePage-add')
  can('access', 'WorkspacePage-edit')
  can('have', 'EmployeeElement')
}

export const onAdUnit = (can: Can) => {
  can('access', 'AdUnitPage-list')
  can('access', 'AdUnitPage-add')
  can('access', 'AdUnitPage-edit')
  can('create', 'AdUnit')
  can('update', 'AdUnit')
  can('delete', 'AdUnit')
}

export const onDomain = (can: Can) => {
  can('access', 'DomainPage-list')
  can('access', 'DomainPage-add')
  can('access', 'DomainPage-edit')
  can('create', 'Domain')
  can('update', 'Domain')
  can('delete', 'Domain')
}

export const onGlobalSetupWorkspace = (can: Can) => {
  can('update', 'GlobalSetupField-workspace')
}

export const onProduct = (can: Can) => {
  can('update', 'Product')
}

export const userByGroup = (user: User, can: Can) => {
  const userBelongsToGroup = (userGroup: UserGroup) => authorizeGroupRole(userGroup, user.role)

  if (userBelongsToGroup('everyone')) {
    onEveryone(can)
    onGlobalSetupAmazonPages(can)
    onGlobalSetupGoogleAdManagerConnection(can)
    onGlobalSetupPrebidBidders(can)
    onGlobalSetupPrebidDefaults(can)
    onGlobalSetupPrebidModule(can)
    onGlobalSetupPrebidProtection(can)
    onPageListEditAccess(can)
  }

  if (userBelongsToGroup('employeesOnly')) {
    onAdUnit(can)
    onAdUnitFields(can)
    onDomain(can)
    onDomainOverridenByYieldbirdRead(can)
    onFinance(can)
    onFinanceAdminOption(can)
    onGlobalSetupPriceGenius(can)
    onGlobalSetupWorkspace(can)
    onPerformanceAdminOption(can)
    onProduct(can)
    onProductStatus(can)
    onUser(can)
    onWorkspace(can)
  }
}
