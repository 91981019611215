import { gql, TypedDocumentNode } from '@apollo/client'

import { GamSetupOrderDTO } from '../types/gamSetupOrderDTO'

export const GAM_SETUP_ORDER_FRAGMENT: TypedDocumentNode<GamSetupOrderDTO> = gql`
  fragment GamSetupOrderFragment on GamSetupOrder {
    bannerTemplateId
    createdAt
    errorMessage
    id
    nativeEnabled
    nativeTemplateId
    status
    updatedAt
  }
`
