import { Delete, Edit, MoreVert } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ListItemButton } from 'components/List'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { useDeleteWorkspaceBidder } from '../hooks/useDeleteWorkspaceBidder'
import { BiddersForTable } from '../types'

type Props = CellContext<BiddersForTable, unknown>

export const ActionsCell = ({
  row: {
    original: { code, id },
  },
}: Props): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const navigate = useNavigate()
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/globalSetup', { keyPrefix: 'prebid.bidders.list' })
  const { anchor, close, isOpen, open } = useMenu()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const { deleteWorkspaceBidder, loading } = useDeleteWorkspaceBidder({ code })

  const handleDelete = (): void => {
    close()
    setIsDialogOpen(true)
  }

  const handleEdit = (): void =>
    navigate(
      generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.EDIT, {
        id,
        tab: 'general',
        workspaceId,
      }),
    )

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      icon={<Edit />}
      onClick={handleEdit}
      text={tC('form.action.edit')}
    />
  )

  const DeleteItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-delete'
      icon={<Delete />}
      onClick={handleDelete}
      text={tC('form.action.delete')}
    />
  )

  const CAN = {
    DELETE: ability.can('delete', 'GlobalSetupFeature-bidder'),
    GO_TO_EDIT_PAGE: ability.can('access', 'GlobalSetupPage-prebid-bidders-edit'),
  } as const

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        {CAN.GO_TO_EDIT_PAGE && <EditItem />}

        {CAN.DELETE && [
          <Divider
            key='divider'
            sx={{ my: 1 }}
          />,
          <DeleteItem key='delete' />,
        ]}
      </Menu>

      <DeleteDialog
        dialog={{ isOpen: isDialogOpen, setOpen: setIsDialogOpen }}
        elementName={code}
        i18nResource={t}
        loading={loading}
        onDelete={() => void deleteWorkspaceBidder(id)}
      />
    </>
  )
}
